<template>
    <div>
        <h2>การตอบสนองอัตโนมัติ</h2>

        <v-tabs v-model="tab">
          <v-tab key="comment">เงื่อนไขการตอบคอมเม้น</v-tab>
          <v-tab key="response">ข้อความตอบกลับบิลออนไลน์</v-tab>
        </v-tabs>                     

        <v-tabs-items v-model="tab">
          <v-tab-item key="comment">
            <v-card class="mt-4 px-6 pt-6">
              <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
              ></v-text-field>
            </v-card> 
        
            <v-card class="mt-4">
              <div class="d-flex pa-4">
                <!--
                <v-btn              
                  @click="deleteMultiple()"
                  :disabled="datatable.selectedItems.length == 0"
                ><v-icon>{{ mdiDeleteOutline }}</v-icon></v-btn>        
                -->
                <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'responserule_create', params: { responserule_id: 'create' } })">เพิ่มเงื่อนไข</v-btn>
              </div>
              <delay-datatable
                :headers="datatable.headers"
                :url="'/setting/responserule/list'"            
                :queryParams="getDatatableQueryParams"
                :options.sync="datatable.options"
                :isSaveState="true"
                @loadState="loadDatatableState"
                v-model="datatable.selectedItems"
                ref="mainDatatable"
              >

              <template          
                v-slot:item.channel_type="{ item }"
              >
                <div class="d-flex align-center">
                  <v-img 
                  v-if="!!helpers.convertChannelTypeToImage(item.channel_type)" 
                  :src="helpers.convertChannelTypeToImage(item.channel_type)"
                  :max-width="25"
                  class="mr-2"
                  ></v-img> {{ helpers.convertChannelTypeToText(item.channel_type) }}               
                </div>            
              </template>

              <template          
                v-slot:item.warehouse="{ item }"
              >
                {{ !!item.warehouse ? item.warehouse.name : '-' }}               
              </template>

              <template          
                v-slot:item.status="{ item }"
              >
                {{ !!item.status ? item.status : '-' }}               
              </template>
              
              <template          
                v-slot:item.actions="{ item }"
              >   
                <v-btn
                  icon
                  @click="$router.push({name: 'responserule_create', params: { responserule_id: item.id }})"
                ><v-icon>{{ $store.getters['auth/GET_ABILITY'].can('update', 'Setting') ? icons.mdiPlaylistEdit : icons.mdiEyeOutline }}</v-icon></v-btn>
                <v-btn
                  icon
                  @click="deleteSingle(item.id, item.rule)"
                ><v-icon>{{ icons.mdiDeleteOutline }}</v-icon></v-btn>
              </template>
              </delay-datatable>
            </v-card>        
          </v-tab-item>

          <v-tab-item key="response">
            <v-form v-model="isFormValid">
              <v-card class="mt-4">                                  
                  <div class="pa-8">
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="example"
                            outlined
                            label="ตัวอย่าง"                                                         
                            hide-details="auto"          
                            dense  
                            readonly
                          ></v-textarea>
                        </v-col>
                        <v-col class="12" md="6">
                          <div for="" v-html="interpretToString(example)" class="example"></div>
                        </v-col>
                      </v-row> 
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="formData.automated_new"
                            outlined
                            label="สร้างรายการขายใหม่"                                                         
                            hide-details="auto"          
                            dense                              
                          ></v-textarea>
                        </v-col>
                        <v-col class="12" md="6">
                          <div for="" v-html="interpretToString(formData.automated_new)" class="example"></div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="formData.automated_confirm"
                            outlined
                            label="ยืนยันการชำระ"                                                         
                            hide-details="auto"          
                            dense                              
                          ></v-textarea>
                        </v-col>
                        <v-col class="12" md="6">
                          <div for="" v-html="interpretToString(formData.automated_confirm)" class="example"></div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="formData.automated_reject"
                            outlined
                            label="ปฏิเสธการชำระ"                                                         
                            hide-details="auto"          
                            dense                              
                          ></v-textarea>
                        </v-col>
                        <v-col class="12" md="6">
                          <div for="" v-html="interpretToString(formData.automated_reject)" class="example"></div>
                        </v-col>
                      </v-row> 
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="formData.automated_delivered"
                            outlined
                            label="สถานะเปลี่ยนเป็นจัดส่งแล้ว"                                                         
                            hide-details="auto"          
                            dense                              
                          ></v-textarea>
                        </v-col>
                        <v-col class="12" md="6">
                          <div for="" v-html="interpretToString(formData.automated_delivered)" class="example"></div>
                        </v-col>
                      </v-row> 
                      <v-row>
                        <v-col cols="12" md="6">
                          <v-textarea
                            v-model="formData.automated_expiry"
                            outlined
                            label="ก่อนหมดอายุ 2 วัน"                                                         
                            hide-details="auto"          
                            dense                              
                          ></v-textarea>
                        </v-col>
                        <v-col class="12" md="6">
                          <div for="" v-html="interpretToString(formData.automated_expiry)" class="example"></div>
                        </v-col>
                      </v-row> 
                    </v-container>
                  </div>

                  <v-card-actions>              
                    <v-btn
                      color="primary"               
                      large          
                      :disabled="!isFormValid"
                      :loading="sendingData"
                      @click="submitForm"
                      v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
                    >
                    แก้ไข
                    </v-btn>             
                  </v-card-actions>
              </v-card>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
        
        <v-dialog
          transition="dialog-top-transition"
          max-width="600"
          v-model="deleteDialog.show"
          persistent
        >
          <v-card>
            <v-toolbar
              color="primary"
              dark
              class="text-h4"
            >ยืนยันที่จะลบ ? {{ deleteDialog.targetName }}</v-toolbar>

            <v-form v-model="deleteDialog.formValid">
              <v-card-text class="pa-12">
                <v-container>
                  <v-row>
                    <v-col>
                      <v-radio-group
                        v-model="deleteDialog.deleteOptions.isMove"
                        column
                      >
                        <v-radio
                          :label="'ฉันต้องการย้ายข้อมูลที่เกี่ยวข้องกับ ' + deleteDialog.targetName + ' ไปไว้ที่ช่องทางอื่น'"
                          :value="true"
                        ></v-radio>
                        <v-radio
                          label="ฉันต้องการลบข้อมูลทั้งหมดที่เกี่ยวข้องกับช่องทางการขายนี้"
                          :value="false"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <template v-if="deleteDialog.deleteOptions.isMove">
                    <v-row>
                      <v-col>
                        <delay-autocomplete
                          outlined
                          hide-selected
                          cache-items
                          v-model="deleteDialog.deleteOptions.targetId"
                          label="ช่องทางการขายที่ต้องการย้ายไป"
                          placeholder="พิมพ์เพื่อค้นหาช่องทางการขาย"
                          no-data-text="ไม่พบข้อมูล"
                          :url="'/setting/responserule/' + deleteDialog.targetId + '/moveList'"
                          dense                        
                          item-text="name"
                          item-value="id"
                          :rules="[validators.required]"
                        ></delay-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="error--text">
                        *** ช่องทางปลายทางควรเป็นประเภทเดียวกันกับต้นทาง
                      </v-col>
                    </v-row>
                  </template>
                  <v-row v-else>
                    <v-col>
                      <v-checkbox
                        v-model="deleteDialog.deleteOptions.lastConfirm"
                        :rules="[validators.required]"
                        label="ฉันเข้าใจและยอมรับ ว่าจะไม่สามารถเข้าถึงข้อมูลที่ถูกลบได้อีก"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>              
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  large
                  color="primary"
                  :disabled="!deleteDialog.formValid"
                  :loading="deleteDialog.loading"
                  @click="deleteSingle()"
                >ลบ</v-btn>
                <v-btn
                  large
                  color="secondary"
                  text
                  @click="deleteDialog.show = false"
                  :disabled="deleteDialog.loading"
                >ยกเลิก</v-btn>
                <!--
                <v-btn
                  v-for="(btn, btn_index) in $store.state.app.confirmDialog.buttons"
                  :key="btn_index"
                  large
                  :color="btn.color"
                  :text="btn.color == 'secondary'"
                  @click="callbackConfirmDialogButton(btn.result)"
                >{{ btn.text }}</v-btn>
                -->
              </v-card-actions>
            </v-form>            
          </v-card>
        </v-dialog>
    </div>
</template>
<script>
import ProductImage from '@/components/ProductImage.vue'
import { asyncDelete, asyncGet, asyncUpdate } from '@/helpers/asyncAxios'
import { convertChannelTypeToImage, convertChannelTypeToText } from '@/helpers/converter.js'
import errorParser from '@/plugins/errorparser'
import { maxLengthValidator, required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiEyeOutline, mdiPlaylistEdit } from '@mdi/js'
export default {
  data() {
    return {
      sendingData: false,
      tab: 'comment',
      example:
        'นี่คือข้อความตัวอย่าง ในการแจ้งเตือนและแสดงผล ของบิล #%%order_number%% มีสินค้าทั้งสิ้น %%product_count%% รายการ รวมเป็นเงิน ทั้งสิ้น %%total%% บาท สามารถตรวจสอบบิลได้ ด้วยการกดปุ่มด้านล่าง\n\n',
      substitute_data: {
        order_number: 'ONL200012001',
        product_count: 6,
        total: '1,253.00',
        link: 'bill.thisisexample.com/12/view/AasdcrTRHhcvDFDGFDF',
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
        },
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'เงื่อนไข',
            value: 'rule',
          },
          {
            text: 'ข้อความตอบกลับ',
            value: 'response_message',
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            //align: 'center',
          },
        ],
      },
      isFormValid: false,
      formData: {
        automated_new: '',
        automated_confirm: '',
        automated_reject: '',
        automated_delivered: '',
        automated_expiry: '',
      },
      deleteDialog: {
        show: false,
        loading: false,
        formValid: false,
        targetName: null,
        targetId: null,
        targetType: 'manual',
        deleteOptions: {
          isMove: true,
          targetId: null,
          lastConfirm: false,
        },
      },
      icons: {
        mdiDeleteOutline,
        mdiPlaylistEdit,
        mdiEyeOutline,
      },
      helpers: {
        convertChannelTypeToText,
        convertChannelTypeToImage,
      },
      validators: {
        required,
        maxLengthValidator,
      },
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        search: this.datatable.search,
      }
    },
  },
  watch: {
    async tab(value) {
      if (value == 0) return

      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      try {
        const { automated_new, automated_confirm, automated_reject, automated_delivered, automated_expiry } =
          await asyncGet('/setting/autoresponse')

        this.formData = {
          automated_new: automated_new,
          automated_confirm: automated_confirm,
          automated_reject: automated_reject,
          automated_delivered: automated_delivered,
          automated_expiry: automated_expiry,
        }
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    async deleteSingle(id, name) {
      const dialogResult = await this.$store.dispatch('app/CONFIRM_DIALOG', {
        title: 'ยืนยันที่จะลบ ?',
        body: 'คุณต้องการที่จะลบ "' + name + '" หรือไม่ ? หากยืนยันไปแล้วจะไม่สามารถกู้ข้อมูลกลับคืนมาได้อีก',
      })

      if (!dialogResult) return

      try {
        const result = await asyncDelete('/setting/responserule', { items: [id] })
        this.$refs.mainDatatable.reload()
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }
    },
    async showDeleteDialog(id, name, type) {
      this.deleteDialog.show = true

      this.deleteDialog.deleteOptions.isMove = true
      this.deleteDialog.deleteOptions.targetId = null
      this.deleteDialog.deleteOptions.lastConfirm = false

      this.deleteDialog.targetId = id
      this.deleteDialog.targetName = name
      this.deleteDialog.targetType = type
    },
    interpretToString(text) {
      let newtext = text.replace('%%order_number%%', this.substitute_data.order_number)
      newtext = newtext.replace('%%product_count%%', this.substitute_data.product_count)
      newtext = newtext.replace('%%total%%', this.substitute_data.total)

      return newtext
    },
    async submitForm() {
      this.sendingData = true
      try {
        await asyncUpdate('/setting/autoresponse', this.formData)
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', errorParser(error))
      }

      this.sendingData = false
    },
  },
  components: {
    ProductImage,
  },
}
</script>
<style scoped>
.example {
  min-height: 100%;
  white-space: pre-line;
  word-break: break-all;
  background-color: mistyrose;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  border-radius: 4px;
}
</style>